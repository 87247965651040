<template>
  <div
    ref="scrollContainer"
    class="flex flex-row gap-14 pb-0 lg:pb-8 md:gap-38 lg:gap-32 px-36 md:px-[40dvw] lg:px-84 snap-mandatory overflow-auto snap-ml-8 no-scrollbar last:pr-4 snap snap-x justify-between"
    :class="{
      '!px-0 self-center': !isShownPro,
    }"
    @scroll="onScrollSetIndicator"
  >
    <template v-for="(c, i) in cards" :key="i">
      <AppPriceCard
        v-if="isShownCard(c)"
        class="snap-center"
        :color-header-bg="c.colorHeaderBg"
        :color-header-txt="c.colorHeaderTxt"
        :features="c.features ?? undefined"
        :feature-text="c.featureText ?? undefined"
        :text-cta="c.textCta"
        :price="c.price"
        :price-condition="c.priceCondition"
        :price-interval="c.priceInterval ?? undefined"
        :price-info="c.priceInfo ?? undefined"
        :price-text="c.priceText ?? undefined"
        :price-sub-text="c.priceSubText ?? undefined"
        :tier="(c.tier as TierLevel)"
        @click:price-card:cta="goToCheckout(c.priceId, c.tier as TierLevel)"
      />
    </template>
  </div>
  <div class="flex w-full flex-col justify-center h-12">
    <AppIndexIndicator
      :total-indices="totalIndices"
      :active-index="activeTabIndex"
    />
    <!-- <div class="mt-4 text-center lg:-mt-12 text-regular-14">
      {{ t('taxNotice') }}
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { TierLevel } from '@/enum/tier-level.enum';
import type { Card } from '~/interfaces/pricing-card.interface';
const { t } = useI18n({
  useScope: 'local',
});
const props = defineProps<{
  isShownBasic?: boolean;
  isShownPro?: boolean;
}>();

const emit = defineEmits<{
  (event: 'click:cta', value: string | undefined, tier: TierLevel): void;
}>();

const activeTabIndex = ref(1);
const isProd = useRuntimeConfig().public.isProd;
const scrollContainer = ref<HTMLDivElement>();

const totalIndices = computed(() => {
  if (props.isShownBasic) {
    return 3;
  }
  if (props.isShownPro) {
    return 2;
  }
  return 1;
});

const cards = computed<Card[]>(() => [
  {
    colorHeaderBg: 'iq-beige',
    colorHeaderTxt: 'iq-black',
    features: [
      { label: t('subscriptions.basic.feature1'), isIncluded: true },
      { label: t('subscriptions.basic.feature2'), isIncluded: true },
      { label: t('subscriptions.basic.feature3'), isIncluded: true },
      { label: t('subscriptions.basic.feature4'), isIncluded: true },
      { label: t('subscriptions.basic.feature5'), isIncluded: true },
    ],
    textCta: t('cta.buy'),
    price: '29€',
    priceInterval: t('cta.month'),
    priceId: isProd
      ? 'price_1OsuiiD8yuHin9TUalbQVCv7'
      : 'price_1OlU1ND8yuHin9TUdpTNpFQI',
    priceInfo: t('priceInfo.basic'),
    priceCondition: t('cta.monthlyCancellable'),
    tier: 'BASIC',
  },
  {
    colorHeaderBg: 'iq-red',
    colorHeaderTxt: 'white',
    features: [
      { label: t('subscriptions.pro.feature1'), isIncluded: true },
      { label: t('subscriptions.pro.feature2'), isIncluded: true },
      { label: t('subscriptions.pro.feature3'), isIncluded: true },
      { label: t('subscriptions.pro.feature4'), isIncluded: true },
      { label: t('subscriptions.pro.feature5'), isIncluded: true },
    ],
    textCta: t('cta.buy'),
    price: '85€',
    priceId: isProd
      ? 'price_1OsuimD8yuHin9TUyJu0QK4q'
      : 'price_1OlU2mD8yuHin9TU3w7RM0rg',
    priceInfo: t('priceInfo.pro'),
    priceInterval: t('cta.month'),
    priceCondition: t('cta.monthlyCancellable'),
    tier: 'PRO',
  },
  {
    colorHeaderBg: 'iq-beige',
    colorHeaderTxt: 'iq-black',
    featureText: t('cta.enterpriseFeatureText'),
    textCta: t('cta.request'),
    title: '€48*',
    priceText: t('cta.individual'),
    priceSubText: t('cta.offer'),
    tier: 'ENTERPRISE',
  },
]);

function isShownCard(card: Card) {
  const router = useRouter();
  if (router.currentRoute.value.path === '/') {
    return true;
  }
  if (
    (card.tier == TierLevel.BASIC && !props.isShownBasic) ||
    (card.tier == TierLevel.PRO && !props.isShownPro)
  ) {
    return false;
  }
  return true;
}
function onScrollSetIndicator() {
  if (!scrollContainer.value || scrollContainer.value.children.length < 2) {
    return;
  }

  const firstCard = scrollContainer.value.children[0] as HTMLElement;
  const secondCard = scrollContainer.value.children[1] as HTMLElement;
  const gap =
    secondCard.offsetLeft - firstCard.offsetLeft - firstCard.offsetWidth;

  const totalWidthPerCard = firstCard.offsetWidth + gap;

  const scrollPosition = scrollContainer.value.scrollLeft;
  const cardIndex = Math.round(scrollPosition / totalWidthPerCard);

  activeTabIndex.value = cardIndex + 1;
}

async function goToCheckout(priceId: string | undefined, tier: TierLevel) {
  if (!priceId) {
    emit('click:cta', undefined, tier);
    return;
  }
  emit('click:cta', priceId, tier);
}

onMounted(async () => {
  // place second card in the middle if three cards are shown
  if (!props.isShownBasic) {
    return;
  }
  await nextTick();
  if (!scrollContainer.value || scrollContainer.value.children.length < 2) {
    return;
  }

  const secondCard = scrollContainer.value.children[1] as HTMLElement;

  const scrollTo =
    secondCard.offsetLeft -
    scrollContainer.value.offsetWidth / 2 +
    secondCard.offsetWidth / 2;
  scrollContainer.value.scrollLeft = scrollTo;
});
</script>

<i18n lang="json">
{
  "de": {
    "subscriptions": {
      "basic": {
        "feature1": "15 Zielkunden Scans /Monat",
        "feature2": "Analyse Zielkundenprofil",
        "feature3": "Analyse Zielkundennutzen",
        "feature4": "Zielgerichtetes Anschreiben",
        "feature5": "20 Scanprofile"
      },
      "pro": {
        "feature1": "50 Zielkunden Scans /Monat",
        "feature2": "Analyse Zielkundenprofil",
        "feature3": "Analyse Zielkundennutzen",
        "feature4": "Zielgerichtetes Anschreiben",
        "feature5": "100 Scanprofile"
      }
    },
    "cta": {
      "buy": "kaufen",
      "month": "Monat",
      "monthlyCancellable": "monatlich kündbar",
      "request": "Anfragen",
      "individual": "individuelles",
      "offer": "Angebot",
      "enterpriseFeatureText": "Wenn du einen erhöhten Bedarf hast oder ergänzende Leistungen wünschst, sprich uns gerne an."
    },
    "priceInfo": {
      "basic": "Ergänzung: Zielkunden Scans </br> ab 1,70 € / Scan*",
      "pro": "Ergänzung: Zielkunden Scans </br> ab 1,50 €  / Scan*"
    },
    "taxNotice": "* zzgl. MwSt"
  },
  "en": {
    "subscriptions": {
      "basic": {
        "feature1": "15 target customer scans/month",
        "feature2": "Target customer profile analysis",
        "feature3": "Target customer benefit analysis",
        "feature4": "Targeted cover letter",
        "feature5": "20 scan profiles"
      },
      "pro": {
        "feature1": "50 target customer scans/month",
        "feature2": "Target customer profile analysis",
        "feature3": "Target customer benefit analysis",
        "feature4": "Targeted cover letter",
        "feature5": "100 scan profiles"
      }
    },
    "cta": {
      "buy": "buy",
      "month": "month",
      "monthlyCancellable": "can be canceled monthly",
      "request": "request",
      "individual": "individual",
      "offer": "offer",
      "enterpriseFeatureText": "If you have increased needs or want additional services, please contact us."
    },
    "priceInfo": {
      "basic": "Supplement: Target customer scans </br> from €1.70 / scan*",
      "pro": "Supplement: Target customer scans </br> from €1.50 / scan*"
    },
    "taxNotice": "* plus VAT"
  }
}
</i18n>
